/* Default */

.App {
  text-align: center;
  font-family: "Arial", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 5px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 8px;
  padding-bottom: 10px;
  border-bottom: 2px solid black;
}

h2 {
  font-family: "Times New Roman", sans-serif;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 0px;
  margin-bottom: 8px;
}

h3 {
  font-size: 20px;
  font-weight: bold;

}

h4 {
  margin-top: 0px;
  padding-top: 0px;
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: bold;
}

p {
  margin-bottom: 16px;
}

a {
  padding: 0px;
  margin: 0px;
}

#read-more-btn {
  font-family: "Times New Roman", sans-serif;
  font-size: 18px;
  color: #555555;
  justify-content: center;
  cursor: pointer;
}

#read-more-btn:hover {
  color: #212529;
}

#rankings-options {
  display: flex;
  justify-content: flex-start;
  border-collapse: collapse;
  margin-bottom: 0px;
}

.select-container {
  padding-left: 16px;
  padding-right: 8px;
  border-collapse: collapse;
  position: relative;
}

.select-container::after {
  /*content: '\e864';*/
  /*!*font-family: sidearm_font, serif;*!*/
  /*position: absolute;*/
  /*top: 50%;*/
  /*right: 24px;*/
  /*transform: translateY(-50%);*/
  /*pointer-events: none;*/
  /*font-size: 1em;*/
  /*color: #c9920e;*/
  /*background: none;*/
}

.rankings-options-select {
  font-size: 18px;
  padding: 15px;
  border-radius: 0px;
  border-collapse: collapse;
}

#year-select {
  width: 100px;
}

#week-select {
  width: 160px;
}

table {
  border-collapse: collapse;
}

.table th {
  border-top: none;
  text-align: left;
  font-weight: bold;
  padding-top: 27px;
  padding-bottom: 27px;
}

.table thead th {
  border-bottom: 2px solid #c9920e;
}

th, td {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
}

tr {
  vertical-align: middle;
}

td {
  text-align: left;
  vertical-align: middle;
  border-top: none;
  border-top: 1px solid #d9d9d9;
}

tr.table-header-row {
  border-top: none;
}

th.position-header {
  padding-left: 16px;
  padding-right: 16px;
}

td.playoff {
  border-top: 1px solid #c9920e;
  border-bottom: 1px solid #c9920e;
}

td.position {
  padding-left: 16px;
  padding-right: 16px;
  font-weight: bold;
  text-align: center;
  border-right: 1px solid #d9d9d9;
}

td.position.playoff {
  color: #c9920e;
  border-right: 1px solid #c9920e;
}

td.logo {
  padding-left: 24px;
}

td.change {
  text-align: left;
}

th.rating-header {
  padding-left: 50px;
  text-align: center;
}

td.rating {
  padding-left: 50px;
  text-align: center;
}

th.record-header {
  padding-left: 50px;
  /*padding-right: 50px;*/
}

td.record {
  text-align: center;
  padding-left: 50px;
  /*padding-right: 50px;*/
}

th.conference-header {
  padding-left: 50px;
}

td.conference {
  padding-left: 50px;
}

td.ranking-row-text {
  vertical-align: middle;
  display: table-cell;
}

.team-name {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mascot {
  color: #555555;
}





#root {
  width: 100%;
  margin: 0px;
  padding: 0px;
  line-height: normal;
}


.container {
    /*max-width: 1000px;*/
    /*margin: auto;*/
  width: 100%;
  margin: 0px;
  margin-right: 0px;
  padding-right: 0px;
  padding: 0px;
  max-width: 100%;
  background-color: #fdfdfd;
}


/* Header */

header {
  display: flex;
  justify-content: center;
}

#app-header {
  /*border-bottom: solid 2px #555555;*/
  background-color: #fdfdfd;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
  /*display:flex;*/
  /*flex-direction: row;*/
  /*justify-content: center;*/
  width: 800px;
  /*background-color: black;*/
  /*position: fixed;*/
  /*top: 0;*/
  /*z-index:9;*/
  font-family: "Monaco", monospace;
}

#app-name {
  margin: 0px;
  /*margin-bottom: 0px;*/
  font-weight: bold;
  /*font-stretch: extra-expanded;*/
  text-align: center;
  margin-left: 0px;
  /*float: left;*/
  color: black;
  justify-items: center;
}

#header-byline-container {
  /*width: 90%;*/

}
#header-byline {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  margin-top: 30px;
}

#logo-link {
  color: black;
  text-decoration: none;
}

#auth {
  float: right;
  display:flex;
}

.auth-button {
  float: right;
  margin: 10px;
  margin-right: 0px;
}

#signup-button {
  margin-right: 5px;
}


/* Main */

.main {
  width: 1250px;
  margin: auto;
  margin-top: 5px;
  font-family: "Arial", sans-serif;
  /*background-color: #FAFAFA;*/
  display:flex;
  justify-content: center;
}

#main-content {
}

/*#team-selection-section {*/
/*  max-width: 80%;*/
/*  float: left;*/
/*}*/

#team-selection {
  float: left;
  /*max-width: 80%;*/
  margin-left: 10px;
}

#team-selection-header {
  margin: 30px;
  margin-bottom: 10px;
  padding-right: 100px;
  position: relative;
  left: 30px;
}

#team-selection-header-title {
  color: #198754;
  font-size: 30px;
}

.team-selection-header-byline {
  color: black;
  font-size: 13px;
  /*width: 75%;*/
}

.link-no-underline {
  text-decoration: none;
}

#team-selection-help-text {
  color: #999999;
  font-size: 13px;
}

#explanation-link {
  margin-top: 15px;
}

#team-selection-interface {
  padding: 0px;
  padding-left: 5px;
  padding-right: 15px;
  border: solid 2px #dee2e6;
  background-color: white;
  float: left;
}

.positions-empty-column {
  border-bottom: none;
  border-right: none;
  border-left: none;
  cursor: default;
}

/*.first-position-column {*/
/*  border-bottom: solid 1px white;*/
/*  border-right: none;*/
/*  border-left: none;*/
/*}*/

/*#lineup-selection-table th {*/
/*  padding: 4px;*/
/*  border-left: solid 1px #dee2e6;*/
/*  !*padding-top: 4px;*!*/
/*  !*padding-bottom: 1px;*!*/
/*}*/

.position-selection-header {
  padding: 4px;
  border-left: solid 1px #dee2e6;
  border-bottom: solid 1px #dee2e6;
}

.selected-position {
  border-left: none;
  border-bottom: solid 1px black;
  padding: 4px;
}

#lineup-selection-table td {
  border-left: none;
  /*border-bottom: none;*/
  border-bottom: solid 1px white;
  cursor: pointer;
  padding: 4px;
  padding-top: 5px;
    min-width: 41px;
}

#lineup-selection-table th {
  padding: 4px;
  padding-top: 5px;
  /*border-bottom: solid 1px #dee2e6;*/
    min-width: 41px;
}

.positions-label-empty-column {
  border-right: solid 1px black;
  border-left: none;
  border-bottom: none;
  color: #999999;
  padding: 4px;
  /*font-weight: bold;*/
}




/*#lineup-selection-table .positions-empty-column td {*/
/*  border-left: none;*/
/*  !*border-bottom: none;*!*/
/*  border-bottom: solid 1px #000000;*/
/*}*/

#team-settings {
  /*border-bottom: solid 1px #dee2e6;*/
  margin-bottom: 10px;
  display: flex;
}

#team-settings-form {
  float: left;
}

.settings-btn-label {
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
}

.settings-btn-group {
  margin: 10px;
  margin-left: 3px;
  margin-right: 0px;
  font-size: 14px;
}

[type='radio'] {
  display: none;
}

.pick-dropdown-option {
  /*width: 10px;*/
}

#clear-button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
}


/* Results */

#results {
  /*display: flex;*/
  float: right;
  padding: 10px;
  padding-bottom: 0px;
  /*padding-right: 0px;*/
  margin: 10px;
  margin-top: 0px;
  /*margin-right: 0px;*/
  /*background-color: #dee2e6;*/
  /*border-left: solid 2px #dee2e6;*/
  border: solid 2px #dee2e6;
  background-color: white;
}

#results-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

#simulate-button {
  margin-left: 10px;
  margin-right: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  float: right;
}

#results-score {
  float: left;
  padding-left: 10px;
}

#results h2 {
  color: #999999;
  padding-top: 2px;
  margin-bottom: 0px;
}

#starting-lineup-results-table th {
  border: solid 1px #dee2e6;
  /*border-top: none;*/
  /*color: #999999;*/
  color: black;
  padding-top: 4px;
  padding: 3px;
  /*border-right: */
}

#starting-lineup-results-table td {
  border-top: none;
  padding-top: 4px;
  padding: 3px;
  border: solid 1px #dee2e6;
  background-color: #FAFAFA;
  width: 45px;
}

#starting-lineup-results-table {
  margin-top: 5px;
  margin-bottom: 0px;
}

.empty-starting-lineup-results td {
  background-color: #FFFFFF;
  border: none;
}

#results-table {
  margin-top: 8px;
  margin-bottom: 10px;
}

#results-table th {
  border-left: none;
  border-bottom: solid 1px #999999;
  color: #999999;
  padding: 1px;
}

#results-table td {
  border: none;
  padding: 5px;
  padding-top: 3px;
  padding-bottom: 2px;
  margin: 0px;
}



/* Explanation */

#explanation {
  margin-top: 40px;
  /*padding-top: 10px;*/
  border-top: solid 1px #555555;
  align-text: center;
}

#explanation-header {
  color: #555555;
  /*color: black;*/
  padding: 20px;
  padding-bottom: 0px;
}

#explanation-main {
  justify-content: center;
  display: flex;
}

#explanation-main-content {
  max-width: 650px;
  text-align: left;
  /*background-color: white;*/
  /*border: solid 2px #dee2e6;*/
  padding: 20px;
  margin-bottom: 40px;
}

#explanation-main-content p {
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
}

#explanation-main-content h4 {
  font-weight: bold;
}
